export type StaticDependency = (() => void) | { init: () => void }

export type ResponsiveDependency =
  | ((isMobile?: boolean) => void)
  | {
      destroy?: (isMobile?: boolean) => void
      init: (isMobile?: boolean) => void
    }

let isInitialized = false

// Functions or `{ init() }` objects that will be called when the page is loaded
const staticDependencies: StaticDependency[] = []

// Functions or `{ init() }` objects that will be called when the page is loaded,
// and every time the viewport changes from mobile to desktop or vice versa
const responsiveDependencies: ResponsiveDependency[] = []

export const KundoDependencies = {
  /**
   *
   * @deprecated use `KUNDO.dependencies.addOnce` instead
   * and make the dependency handle viewport changes itself
   */
  add(objs: ResponsiveDependency[]) {
    responsiveDependencies.push(...objs)
  },

  /**
   * Add a JavaScript function that will be initialized when the page is loaded
   */
  addOnce(objs: StaticDependency[]) {
    staticDependencies.push(...objs)
  },

  load(isMobile?: boolean) {
    if (!isInitialized) {
      isInitialized = true
      staticDependencies.forEach((s) => {
        if (isFunction(s)) {
          s.call(s, isMobile)
        } else if (isFunction(s.init)) {
          s.init.call(s)
        }
      })
    }

    // first run destroy on all imported
    responsiveDependencies.forEach((s) => {
      if (!isFunction(s) && isFunction(s.destroy)) {
        s.destroy.call(s, !isMobile)
      }
    })

    // then run the import or the init on the module
    responsiveDependencies.forEach((s) => {
      if (isFunction(s)) {
        s.call(s, isMobile)
      } else if (isFunction(s.init)) {
        s.init.call(s, isMobile)
      }
    })
  },

  clear() {
    isInitialized = false
    staticDependencies.length = 0
    responsiveDependencies.length = 0
  },
}

KUNDO.dependencies = KundoDependencies

function isFunction(obj: any): obj is Function {
  return typeof obj === 'function'
}
