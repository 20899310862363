import { setCookie, checkCookieExists } from '../helpers'

export const MenuBadge = () => {
  // show badge "New" for statistics menu item

  if (!window.location.href.includes('statistics') && !checkCookieExists('show_csat_badge')) {
    setCookie('show_csat_badge', 'true', '/', 365)
  } else if (window.location.href.includes('statistics') && checkCookieExists('show_csat_badge')) {
    setCookie('show_csat_badge', 'false', '/', 365)
  }

  const menuItems = document.querySelectorAll('.dashboard-nav__link-item')

  menuItems.forEach((element) => {
    const badge = element.querySelector('.dashboard-nav__link-sub-item-badge')
    checkCookieExists('show_csat_badge', 'true')
      ? badge?.classList.remove('hidden')
      : badge?.classList.add('hidden')
  })
}

MenuBadge()
