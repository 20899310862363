import 'tooltipster'

import { EVT_LOAD } from 'platform/events'

export const KundoTooltip = {
  init(isMobile?: boolean) {
    if (!isMobile) {
      $.tooltipster.setDefaults({
        arrow: false,
        animationDuration: 200,
        theme: 'tooltipster-kundo-theme',
        interactive: true,
        delay: 30, // this is to match previous tooltip behaviour. tooltipsters default is 300
        maxWidth: 200, // match previous config
        zIndex: 3000, // match previous config
        functionReady: function (origin, tooltip) {
          const tootltipRoot = tooltip.tooltip
          tootltipRoot.setAttribute('data-hj-suppress', 'true')
        },
      })
      KUNDO.tooltip.update()
      window.addEventListener(EVT_LOAD, () => KUNDO.tooltip.update())
    }
  },
  update() {
    const slowDelay = 100
    const done = '.tooltipstered'
    const slowTooltips = () => $('.fn-tooltip-slow').add('[data-fn-tooltip-slow]').not(done)
    slowTooltips().filter('.fn-tooltip-left').tooltipster({ delay: slowDelay, side: 'left' })
    slowTooltips().filter('.fn-tooltip-right').tooltipster({ delay: slowDelay, side: 'right' })
    slowTooltips().tooltipster({ delay: slowDelay })

    $('.fn-tooltip').add('[data-fn-tooltip]').not(done).tooltipster()
    $('.fn-tooltip-veryslow')
      .add('[data-fn-tooltip-veryslow]')
      .not(done)
      .tooltipster({ delay: 500 })
    $('[data-fn-tooltip-slow-extend]').not(done).tooltipster({ delay: slowDelay, maxWidth: null })
    $('[data-fn-tooltip-ajax]')
      .not(done)
      .tooltipster({
        updateAnimation: 'scale',
        functionBefore: function (instance, helper) {
          const origin = $(helper.origin)
          const ajaxUrl = origin.attr('data-detail-url')!
          if (origin.data('loaded') !== true) {
            $.get(ajaxUrl, (data = { excerpt: null }) => {
              const excerpt = data.excerpt || ''
              instance.content(excerpt)
              origin.data('loaded', true)
            })
          }
        },
      })
    $('[data-fn-content-tooltip]')
      .not(done)
      .tooltipster({
        functionBefore: (instance, helper) => instance.content($($(helper.origin).html())),
      })
  },
}

KUNDO.tooltip = KundoTooltip
