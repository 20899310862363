import dialogPolyfill from 'dialog-polyfill/dialog-polyfill'
import { v4 as uuidv4 } from 'uuid'

export default class Modal {
  dialogElement: any
  wrapperElement: any
  closeModalOnBackdropClick: any
  lastFocusElement: any

  constructor(
    dialogElement,
    { wrapperElement = document.querySelector('.wrapper'), closeModalOnBackdropClick = false } = {},
  ) {
    this.dialogElement = dialogElement
    this.wrapperElement = wrapperElement
    this.closeModalOnBackdropClick = closeModalOnBackdropClick
    this.lastFocusElement = document.activeElement

    dialogPolyfill.registerDialog(this.dialogElement)

    if (!this.dialogElement.hasAttribute('aria-labelledby')) {
      const title = this.dialogElement.querySelector('h1, h2, h3, h4, h5, h6')
      if (title) {
        if (!title.id) {
          title.id = uuidv4()
        }
        this.dialogElement.setAttribute('aria-labelledby', title.id)
      }
    }

    if (this.closeModalOnBackdropClick) {
      this.dialogElement.addEventListener('click', (event) => {
        if (this.dialogElement === event.target) {
          this.close()
        }
      })
    }

    this.dialogElement.addEventListener('close', () => {
      document.body.classList.remove('no-scroll')
      if (this.wrapperElement) {
        this.wrapperElement.removeAttribute('aria-hidden')
      }
      if (
        this.lastFocusElement.nodeName.toLowerCase() === 'a' ||
        this.lastFocusElement.nodeName.toLowerCase() === 'button'
      ) {
        this.lastFocusElement.focus()
      }
    })

    $(this.dialogElement)
      .find('.fn-close')
      .add('[data-fn-close]')
      .on('click', () => this.close())
  }

  show() {
    this.lastFocusElement = document.activeElement
    this.dialogElement.showModal()

    const possibleFirstFocusElement = this.dialogElement.querySelector(
      'input:not([type="hidden"]), textarea',
    )
    if (possibleFirstFocusElement) {
      possibleFirstFocusElement.setAttribute('autofocus', 'autofocus')
      possibleFirstFocusElement.focus()
    } else {
      this.dialogElement.setAttribute('tabindex', '-1')
      this.dialogElement.focus()
    }

    document.body.classList.add('no-scroll')
    if (this.wrapperElement) {
      this.wrapperElement.setAttribute('aria-hidden', 'true')
    }
  }

  close() {
    // https://gist.github.com/samthor/babe9fad4a65625b301ba482dad284d1
    this.dialogElement.close()
  }
}
