import A11yDialog from 'a11y-dialog'

import IconX from '@icons/x.svg'

// Event that can be dispatched to close the current overlay
export const CLOSE_CURRENT_OVERLAY_MSG = 'kundo:close-current-overlay'

// Event dispatched when the overlay is closed
export const OVERLAY_WAS_CLOSED_MSG = 'kundo:overlay-was-closed'

const CONTAINER_ID = 'overlay-container'

//
// Global singleton for the A11yDialog instance
//
let DIALOG: A11yDialog | null = null

export const KundoOverlay = {
  init() {
    window.addEventListener('keydown', closeOnEsc)
    window.addEventListener('message', (evt) => {
      if (evt.data.type === CLOSE_CURRENT_OVERLAY_MSG) {
        KUNDO.overlay.close()
      }
    })
    KUNDO.overlay.update()
    $('[data-fn-dialog-abort]').on('click', () => KUNDO.overlay.close())
  },

  update() {
    $('[data-fn-overlay]').each((_, elm) => KUNDO.overlay.attach(elm))
  },

  attach(target: JQuery<HTMLElement> | HTMLElement) {
    // Keep track on the DOM node itself if we've already setup
    // the overlay script for it, so KUNDO.overlay.init can be
    // called multiple times without memory leaks
    target = $(target)[0]
    if (!target || target['kundoOverlay']) {
      return
    }
    target['kundoOverlay'] = new Overlay(target)
  },

  attachHTMLElement(target: HTMLElement) {
    target['kundoOverlay'] = new Overlay(target)
  },

  open() {
    DIALOG?.show()
  },

  close() {
    DIALOG?.hide()

    if (window.parent !== window) {
      // Tell our parent to close us if we are the popup
      window.parent.postMessage({ type: CLOSE_CURRENT_OVERLAY_MSG }, '*')
    } else {
      DIALOG?.hide()
    }
  },

  getFreshDialogContentRoot(options?: { variant?: string }): HTMLElement {
    teardownDialog()
    const { content, dialog } = setupDialog(options)
    DIALOG = dialog
    return content.get(0)
  },
}

KUNDO.overlay = KundoOverlay

function teardownDialog() {
  KUNDO.overlay.close()
  DIALOG?.destroy()
  document.getElementById(CONTAINER_ID)?.remove()
}

function setupDialog(options?: { variant?: string }) {
  // Set up the dialog HTML structure
  // <dialog__container
  //   <dialog__overlay
  //   <dialog__content-wrapper
  //     <dialog__close-btn
  //     <dialog__content-inner

  const container = $('<div id="overlay-container" class="dialog__container">')
    .appendTo(document.body)
    .append($('<div data-a11y-dialog-hide class="dialog__overlay">'))

  const contentWrapper = $('<div role="document" class="dialog__content-wrapper">')
  if (options?.variant) {
    contentWrapper.addClass(options?.variant)
  }
  contentWrapper.appendTo(container)

  $('<button type="button" class="dialog__btn-close" data-a11y-dialog-hide>')
    .append(IconX)
    .attr('aria-label', TRANSLATIONS.close)
    .appendTo(contentWrapper)

  const content = $('<div class="dialog__content-inner"></div>').appendTo(contentWrapper)

  const dialog = new A11yDialog(container[0])
  dialog.on('hide', () => document.dispatchEvent(new Event(OVERLAY_WAS_CLOSED_MSG)))

  return {
    dialog,
    content,
  }
}

export default class Overlay {
  elm: JQuery<HTMLElement>
  url: string

  constructor(elm: JQuery<HTMLElement> | HTMLElement) {
    this.elm = $(elm)

    if (this.elm.is('form')) {
      this.elm.on('submit', async () => {
        this.loadFormFrame()
      })
    } else if (this.elm.is('a')) {
      this.elm.on('click', (evt) => {
        evt.preventDefault()
        if (this.elm.data().hasOwnProperty('stopPropagation')) evt.stopPropagation()
        this.loadLinkFrame()
      })
    }
  }

  getOverlayVariantClass(): string {
    return this.elm.attr('data-overlay-class') || 'big'
  }

  getURL(): string | undefined {
    const elm = this.elm[0] as any
    return elm?.action ?? elm?.href ?? undefined
  }

  async loadFormFrame() {
    const contentRoot = KUNDO.overlay.getFreshDialogContentRoot({
      variant: this.getOverlayVariantClass(),
    })

    const frameName = 'overlay-frame'
    let frame = $<HTMLIFrameElement>('<iframe>')
    frame.attr('name', frameName)
    this.elm.attr('target', frameName)

    frame.appendTo(contentRoot)

    await waitForLoad(frame)

    KUNDO.overlay.open()
    resizeFrame(frame)
  }

  async loadLinkFrame() {
    const contentRoot = KUNDO.overlay.getFreshDialogContentRoot({
      variant: this.getOverlayVariantClass(),
    })

    const url = this.getURL()
    if (!url) {
      return
    }

    let frame = $<HTMLIFrameElement>('<iframe title="iframe">')
      .attr('src', url)
      .attr('data-hj-allow-iframe', '')
      .attr('id', 'mail-iframe')
    frame.appendTo(contentRoot)
    frame.hide()

    await waitForLoad(frame)

    KUNDO.overlay.open()
    frame.show()
    resizeFrame(frame)

    // For JavaScript-heavy iframes like the new mail dialog we need to resize them twice,
    // after they have had a change to run their own size-affecting JavaScript.
    setTimeout(() => resizeFrame(frame), 50)
  }
}

function waitForLoad(elm: JQuery<HTMLElement>) {
  return new Promise<void>((resolve) => elm.on('load', () => resolve()))
}

function resizeFrame(frame: JQuery<HTMLIFrameElement>) {
  const frameBody = frame[0].contentWindow?.document.body
  if (!frameBody) {
    return
  }
  const height = frameBody.scrollHeight
  frame.css('height', height ? `${height + 2}px` : `100%`)
}

function isAnyTextEditorPopupVisible() {
  for (const editor of $.FroalaEditor.INSTANCES) {
    if (editor.popups.areVisible()) {
      return true
    }
  }
}

function closeOnEsc(evt: KeyboardEvent) {
  if (evt.key === 'Escape') {
    if (isAnyTextEditorPopupVisible()) {
      return
    }
    KUNDO.overlay.close()
  }
}
