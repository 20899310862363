import Modal from './Modal'
import { TagField } from 'editors/toolbox/tagfield'
import { forEachElement } from './utils'

function modalContent(modalElement) {
  let applyJavaScriptToMarkup

  const submitForm = (formElement) => {
    const formData = new FormData(formElement)

    fetch(formElement.getAttribute('action'), {
      credentials: 'same-origin',
      method: formElement.getAttribute('method'),
      body: formData,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
      .then((response) => {
        if (response.ok) {
          return response
        }
        throw new Error(response.statusText)
      })
      .then((response) => response.text())
      .then((html) => {
        const div = document.createElement('div')
        div.insertAdjacentHTML('beforeend', html)
        const content = div.querySelector('#modalContent')
        const oldContent = modalElement.querySelector('#modalContent')
        oldContent.parentElement.insertAdjacentElement('beforeend', content)
        oldContent.parentElement.removeChild(oldContent)

        applyJavaScriptToMarkup()
      })
      .catch((error) => {
        console.warn(error)
      })
  }

  applyJavaScriptToMarkup = () => {
    const formElement = modalElement.querySelector('form')
    if (formElement) {
      formElement.addEventListener('submit', (submitEvent) => {
        submitEvent.preventDefault()
        submitForm(formElement)
      })

      const tagFieldElement = formElement.querySelector('.fn-tag-field')
      if (tagFieldElement) {
        new TagField($(tagFieldElement))
      }
    }
  }

  applyJavaScriptToMarkup()
}

function createModal() {
  let modal
  forEachElement('.fn-modal', (modalButton) => {
    modalButton.setAttribute('role', 'button')
    modalButton.setAttribute('aria-haspopup', 'true')

    modalButton.addEventListener('keypress', (event) => {
      if (event.key === ' ') {
        event.preventDefault()
        modalButton.click()
      }
    })

    modalButton.addEventListener('click', (event) => {
      event.preventDefault()
      const modalClassName = 'modal'
      let modalElement = document.querySelector(`.${modalClassName}`)

      if (!modalElement) {
        // @ts-expect-error
        fetch(modalButton.getAttribute('href'), {
          credentials: 'same-origin',
          headers: new Headers({
            'X-Requested-With': 'XMLHttpRequest',
          }),
        })
          .then((response) => {
            if (response.ok) {
              return response
            }
            throw new Error(response.statusText)
          })
          .then((response) => response.text())
          .then((html) => {
            const div = document.createElement('div')
            div.insertAdjacentHTML('beforeend', html)
            modalElement = div.querySelector('dialog')

            if (modalElement) {
              document.body.insertAdjacentElement('beforeend', modalElement)
              modal = new Modal(modalElement, {
                // @ts-expect-error
                initElement: modalButton,
              })
              modal.show()

              modalContent(modalElement)

              modalElement.addEventListener('close', () => {
                // @ts-expect-error
                if (modalElement.querySelector('[data-success="success"]')) {
                  window.location.reload()
                }
                // @ts-expect-error
                modalElement.parentElement.removeChild(modalElement)
              })
            } else {
              // @ts-expect-error
              window.location.href = modalButton.getAttribute('href')
            }
          })
          .catch((error) => {
            throw new Error(error)
          })
      } else {
        modal.show()
      }
    })
  })
}

export default createModal
