(function($) {
    $.fn.toggleAttr = function(attribute, value1, value2) {
        var current = $(this).attr(attribute)
        if(current == value1){
            $(this).attr(attribute,value2);
        }
        else{
            $(this).attr(attribute,value1);
        }
    };
})(jQuery);
