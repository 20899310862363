export const setCookie = (name: string, value: string, url: string, days: number) => {
  const date = new Date()
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
  const expires = 'expires=' + date.toUTCString()
  const path = 'path=' + url
  document.cookie = `${name}=${value};${expires};${path}`
}

export const checkCookieExists = (cookieName: string, expectedValue?: string): boolean => {
  const cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim()
    const [name, value] = cookie.split('=')
    if (expectedValue) {
      if (name === cookieName && value === expectedValue) {
        return true
      }
    } else {
      if (cookie.startsWith(cookieName + '=')) {
        return true
      }
    }
  }
  return false
}

export const fetchData = async ({
  url,
  method = 'GET',
  headers,
  ...rest
}: {
  url: string
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS' | 'HEAD' | 'CONNECT' | 'TRACE'
  headers?: HeadersInit
} & RequestInit): Promise<Response> => {
  const response = await fetch(url, {
    method: method,
    headers: {
      Accept: 'application/json',
      ...headers,
    },
    ...rest,
  })

  return response
}
