let currentFoldoutElm: JQuery<HTMLElement> | null = null

export function initFoldoutToggle() {
  $('[data-fn-foldout-toggle]').on('click', toggleFoldout)
}

function toggleFoldout() {
  const elm = $(this)
  // first close any other open foldout
  if (currentFoldoutElm && currentFoldoutElm[0] !== elm[0]) {
    hideFoldout()
  }
  currentFoldoutElm = elm

  // then attach/detach closing events for the new foldout
  if (!currentFoldoutElm.is('.expanded')) {
    attachClosingEvents()
  } else {
    detachClosingEvents()
  }

  // close or open the foldout using KUNDO.toggle
  KUNDO.toggle.toggle(currentFoldoutElm, customFadeToggle)
  return false
}

function closeOnEsc(evt: JQuery.KeyUpEvent) {
  if (evt.key === 'Escape') {
    hideFoldout()
  }
}

function closeOnOutsideClick(evt: JQuery.ClickEvent) {
  // dont close if target should be clickable, otherwise always close
  if (!$(evt.target!).closest('[data-fn-foldout-allow-click]').length) {
    hideFoldout()
  }
}

function hideFoldout() {
  detachClosingEvents()
  currentFoldoutElm?.removeClass('expanded')
  const elm = KUNDO.toggle.getTargetElement(currentFoldoutElm)
  elm.fadeOut()
  currentFoldoutElm = null
}

function attachClosingEvents() {
  $(document).on('keyup', closeOnEsc)
  $(document).on('click', closeOnOutsideClick)
}

function detachClosingEvents() {
  $(document).off('keyup', closeOnEsc)
  $(document).off('click', closeOnOutsideClick)
}

function customFadeToggle() {
  // handles fades to inline block
  const elm = $(this)
  const display = elm.attr('data-toggle-display') === 'inline-block' ? 'inline-block' : 'block'

  if (elm.is(':visible')) {
    elm.fadeOut()
  } else {
    elm.fadeIn().css('display', display)
  }
}
