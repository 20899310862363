export const Forms = (function () {
  let isInitialized = false
  return {
    ajaxPostForm() {
      const form = $(this)
      const callback = KUNDO.utils.getFunctionByName(form.attr('data-ajax-success'))
      KUNDO.forms.ajaxPost(form, callback)
      return false
    },

    autoPost() {
      const form = $(this).closest('form')
      const callback = KUNDO.utils.getFunctionByName($(this).attr('data-ajax-success'))
      KUNDO.forms.ajaxPost(form, callback, $(this))
    },

    autoPostReload() {
      const elm = $(this)
      let form = $(`#${elm.attr('form')}`)
      if (!form.length) {
        form = elm.closest('form')
      }
      form.submit()
    },

    ajaxPost(form, success, field?) {
      // first clear all placeholder texts (IE hack)
      form.find('input').each(clearPlaceholders)
      let action = form.attr('action')
      if (field) {
        const prefix = action.indexOf('?') < 0 ? '?' : '&'
        action += `${prefix}field=${encodeURIComponent(field.attr('name'))}`
      }
      $.post(action, form.serialize(), success)
    },

    init() {
      if (!isInitialized) {
        $('input.fn-autopost').change(this.autoPost)
        $('select.fn-autopost').change(this.autoPost)
        $('form.fn-ajaxpost').submit(this.ajaxPostForm)
        $('.fn-autosubmittingselect').change(this.autoPostReload)
      }

      isInitialized = true
    },
  }
})()

function clearPlaceholders() {
  const field = $(this)
  if (field.val() === field.attr('placeholder')) {
    field.val('')
  }
}

KUNDO.forms = Forms
