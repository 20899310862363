import * as Sentry from '@sentry/browser'

export default function setupErrorReporting() {
  window.Sentry = Sentry

  const { SENTRY_CONFIG } = window
  // @ts-ignore
  if (process.env.NODE_ENV === 'development' || !SENTRY_CONFIG) {
    return
  }

  Sentry.init({
    dsn: SENTRY_CONFIG.dsn,
    release: SENTRY_CONFIG.release,
    environment: SENTRY_CONFIG.environment,
    autoSessionTracking: false,
    ignoreErrors: [
      // fmt: expand
      '_satellite',
      'SecurityError',
      'document.getElementsByClassName.ToString',
    ],
    denyUrls: [
      /www.googletagmanager.com/,
      /static.hotjar.com/,
      /chat.kundo.se/,
      /chat-stage.kundo.se/,
    ],
  })

  Sentry.configureScope((scope) => {
    const { tags, user } = SENTRY_CONFIG

    // Additional error context to more easily filter and find issues
    // See: https://docs.sentry.io/platforms/javascript/?platform=browser#tagging-events
    if (tags) {
      for (let tag in tags) {
        scope.setTag(tag, tags[tag])
      }
    }

    // Additional error context to see how many and which editors an error affects
    // See: https://docs.sentry.io/platforms/javascript/?platform=browser#capturing-the-user
    if (user) {
      scope.setUser(user)
    }
  })
}
