import Modal from './Modal'

export function initImageLightboxModal() {
  $('.content :not(a) > img')
    .add('[data-fn-lightbox-images] :not(a) > img')
    .add('[data-fn-lightbox-images] > img')
    .attr('tabindex', '0')
    .attr('aria-haspopup', 'true')
    .on('keypress', (event) => {
      if (event.key === ' ' || event.key === 'Enter') {
        event.preventDefault()
        event.currentTarget.click()
      }
    })
    .on('click', (event) => {
      event.preventDefault()
      const dialogElm = dialogElementFor(event.currentTarget as HTMLImageElement)
      dialogElm.appendTo(document.body)
      const modal = new Modal(dialogElm[0], { closeModalOnBackdropClick: true })
      modal.show()
    })
}

function dialogElementFor(elm: HTMLImageElement) {
  const a = $('<a target="_blank" rel="noopener noreferrer">')
    .attr('href', elm.src)
    .text(parseFileName(elm.src))

  const buttonIcon =
    '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"><path stroke="currentColor" stroke-width="2" d="M1.5 1.5l15 15m0-15l-15 15" stroke-linecap="round"></path></svg>'
  const button = $(`<button type="button" class="modal__close" data-fn-close>`)
    .attr('aria-label', TRANSLATIONS.close)
    .append(buttonIcon)

  const modalElement = $('<dialog class="modal modal--lightbox">')
    .append(button)
    .append(elm.outerHTML)
    .append($('<small>').append(a))
  modalElement.on('close', () => modalElement.remove())
  return modalElement
}

function parseFileName(fileString) {
  const fileNameArray = fileString.replace(/\/$/, '').split('/')
  return fileNameArray[fileNameArray.length - 1]
}
