/* Module for tagging using the Selectize js-module */
import debounce from 'lodash/debounce'

import IconX from '@icons/x.svg'
import { tryParseJSON } from '../utils/functools'

type TagData = { name: string; slug: string; group: string }

const POST_DEBOUNCE_MS = 300

export class TagField {
  inputField: JQuery<HTMLElement>
  onChange?: (e: { tags: string[] }) => void

  constructor(
    inputField: HTMLElement | JQuery<HTMLElement>,
    options?: { onChange?: (e: { tags: string[] }) => void },
  ) {
    this.tagsChanged = this.tagsChanged.bind(this)
    this.itemCreate = this.itemCreate.bind(this)
    this.optionCreate = this.optionCreate.bind(this)

    this.inputField = $(inputField)
    this.onChange = options?.onChange

    const availableTags = tryParseJSON<TagData[]>(this.inputField.attr('data-availabletags')) || []
    const optGroups = tryParseJSON<string[]>(this.inputField.attr('data-opt-groups') || 'null')
    const hasAvailableTags = availableTags.length > 0
    const readOnly = this.inputField.data('read-only')
    const showDropdown = this.inputField.data('show-dropdown')
    const dropdownDirection = this.inputField.attr('data-dropdown-direction') || 'down'
    const followTagLinks = this.inputField.data('follow-tag-links')
    let that = this

    this.inputField.selectize({
      delimiter: ',',
      selectOnTab: false,
      hideSelected: true,
      create: readOnly ? false : this.itemCreate,
      plugins: this.getPlugins(followTagLinks),
      openOnFocus: showDropdown && hasAvailableTags && availableTags.length < 1000,
      render: {
        option_create: this.optionCreate,
      },
      options: availableTags.map((tag) => this.itemCreate(tag)),
      createFilter: (input: string) => that.isUnique(input),
      optgroups: optGroups && optGroups.map((s) => ({ value: s, label: s })),
      optgroupField: 'group',
      lockOptgroupOrder: true,
      dropdownDirection,
      onChange: this.tagsChanged,
      onInitialize() {
        setInputMinWidth(this)
        that.onChange?.({ tags: that.getSelectize().items })
      },
    })
    this.updateGuiContainers(this.inputField.val())
  }

  destroy() {
    this.getSelectize().destroy()
  }

  getPlugins(followTagLinks) {
    const plugins: any = {
      remove_button: { title: '', label: IconX },
      dropdown_direction: {},
    }
    if (followTagLinks) {
      plugins.tag_link = {}
    }
    return plugins
  }

  getSelectize() {
    return (this.inputField[0] as any).selectize
  }

  isUnique(input: string) {
    input = input.toLowerCase()
    const values: string[] = this.getSelectize().getValue().split(',')
    return values.filter((value) => value.toLowerCase() === input).length == 0
  }

  tagsChanged(tags: string) {
    const { inputField } = this
    const ajaxEnabled = inputField.data('ajax-enabled')
    let url = inputField.attr('data-ajax-update-url')
    url ||= inputField.parents('form').attr('action')
    if (ajaxEnabled && url) {
      debouncedPost(url, { tags })
    }

    this.updateGuiContainers(tags)
    this.onChange?.({ tags: this.getSelectize().items })
  }

  itemCreate(input: TagData) {
    if (typeof input === 'string') {
      return { text: input, value: input }
    }
    if (typeof input === 'object') {
      return { text: input.name, value: input.slug, group: input.group }
    }
    return null
  }

  optionCreate(data, escape) {
    const text = escape(data.input)
    return `<div class='create'>${TRANSLATIONS.add_tag_label} ${text}</div>`
  }

  updateGuiContainers(tagString) {
    if (this.inputField.attr('data-disable-gui-updates')) {
      return
    }

    const tags: string[] = tagString.split(',').filter(Boolean)

    // Update tag list
    let tagContent = tags.join(', ')
    $('.fn-hint-tags').text(tagContent.length ? `(${tagContent})` : '')

    // Update public tags list in dialog
    const publicTagsContainer = document.querySelector('.fn-tags-container')
    if (publicTagsContainer) {
      const baseUrl = publicTagsContainer.getAttribute('data-tag-page-url')
      const getTagUrl = (tag) => `${baseUrl}?q=${encodeURIComponent(tag)}`
      const links = tags.map((tag) =>
        $(`<a>`).attr('href', getTagUrl(tag)).text(tag).prop('outerHTML'),
      )

      publicTagsContainer.innerHTML = links.join(', ')
      if (publicTagsContainer.children.length) {
        publicTagsContainer.children[0].classList.add('f-icon-tag')
        publicTagsContainer.classList.remove('hidden')
      } else {
        publicTagsContainer.classList.add('hidden')
      }
    }
  }
}

const debouncedPost: (url: string, data: any) => void = debounce(
  (url: string, data: any) => $.post(url, data),
  POST_DEBOUNCE_MS,
)

function setInputMinWidth(module) {
  // Hack to avoid that input text flickers on typing
  // Problem reported here https://github.com/brianreavis/selectize.js/issues/594
  module.$control_input.on('keydown', function () {
    const newMinWidth = parseInt(this.style.width) + 20
    $(this).css('min-width', newMinWidth)
  })
}
