export function identity<T>(x: T): T {
  return x
}

export function compose<A>(): (arg: A) => A
export function compose<A, B>(fn: (arg: A) => B): (arg: A) => B
export function compose<A, B, C>(fn0: (arg: B) => C, fn1: (arg: A) => B): (arg: A) => C
export function compose<A, B, C, D>(
  fn0: (arg: C) => D,
  fn1: (arg: B) => C,
  fn2: (arg: A) => B,
): (arg: A) => D
export function compose<A, B, C, D, E>(
  fn0: (arg: D) => E,
  fn1: (arg: C) => D,
  fn2: (arg: B) => C,
  fn3: (arg: A) => B,
): (arg: A) => E
export function compose<A, B, C, D, E, F>(
  fn0: (arg: E) => F,
  fn1: (arg: D) => E,
  fn2: (arg: C) => D,
  fn3: (arg: B) => C,
  fn4: (arg: A) => B,
): (arg: A) => F
export function compose<A, B, C, D, E, F, G>(
  fn0: (arg: F) => G,
  fn1: (arg: E) => F,
  fn2: (arg: D) => E,
  fn3: (arg: C) => D,
  fn4: (arg: B) => C,
  fn5: (arg: A) => B,
): (arg: A) => G
export function compose<A, B, C, D, E, F, G, H>(
  fn0: (arg: G) => H,
  fn1: (arg: F) => G,
  fn2: (arg: E) => F,
  fn3: (arg: D) => E,
  fn4: (arg: C) => D,
  fn5: (arg: B) => C,
  fn6: (arg: A) => B,
): (arg: A) => H
export function compose<A, B, C, D, E, F, G, H, I>(
  fn0: (arg: H) => I,
  fn1: (arg: G) => H,
  fn2: (arg: F) => G,
  fn3: (arg: E) => F,
  fn4: (arg: D) => E,
  fn5: (arg: C) => D,
  fn6: (arg: B) => C,
  fn7: (arg: A) => B,
): (arg: A) => I
export function compose(...fns: any[]): any {
  return (arg) => fns.reduceRight((arg, fn) => fn(arg), arg)
}

export function range(start: number, end: number): number[] {
  const items: number[] = []
  for (let num = start; num < end + 1; num++) {
    items.push(num)
  }
  return items
}

export function assoc<T, U>(obj: T, key: string, value: U): T {
  return { ...obj, [key]: value }
}

export function assocIn<T, U>(obj: T, [key, ...rest]: string[], value: U) {
  if (rest.length === 0) return assoc(obj, key, value)
  return assoc<T, U>(obj, key, assocIn<T, U>(obj[key] || {}, rest, value))
}

export function dissoc<T>(obj: T, key: string) {
  const copy = { ...obj }
  delete copy[key]
  return copy
}

export function dissocIn<T>(obj: T, [key, ...rest]: string[]) {
  if (rest.length === 0) return dissoc(obj, key)
  return assoc(obj, key, dissocIn(obj[key] || {}, rest))
}

export function debounce(fn, timeout = 300) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(this, args)
    }, timeout)
  }
}

/**
 * Filter out duplicates from an array of objects based on a key,
 * keeping the first occurrence of each duplicate.
 **/
export function filterUnique<T>(array: T[], key: (item: T) => any = identity): T[] {
  return array.filter((item, i) => {
    const prevItems = array.slice(0, i)
    return !prevItems.some((prevItem) => key(prevItem) === key(item))
  })
}

export function tryParseJSON<T = object>(val: any): null | T {
  try {
    return JSON.parse(val)
  } catch (e) {
    return null
  }
}
