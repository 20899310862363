/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import 'vendor/jquery.timeago.custom.js'
import 'vendor/jquery.touchwipe.min.js'
import 'vendor/jquery-ui-1.12.1.min.js'
import 'vendor/jquery.textarea-helper.js'
import 'vendor/jquery.rangyinputs.min.js'
import 'vendor/jquery.fileupload.js'
import 'vendor/jquery.toggleattr.js'
import 'vendor/enquire-1.5.5.min.js'
import 'vendor/tinycon.custom.js'
import 'vendor/ajax_csrf.js'

window.KUNDO = window.KUNDO || {}

$(() => KUNDO.main.init())

KUNDO.main = {
  init() {
    window.isWidescreen = isWidescreen()
    window.isRetina = window.devicePixelRatio >= 2
    $.timeago.settings.strings = TRANSLATIONS.timeago

    // mobile vs widescreen on/off
    const { enquire } = window
    const shouldDegrade = true
    const widescreenHandlers = {
      match: () => KUNDO.dependencies.load(false), // widescreen
      unmatch: () => KUNDO.dependencies.load(true), // mobile
      setup: () => {
        if (!window.isWidescreen) {
          KUNDO.dependencies.load(true)
        }
      },
    }
    enquire
      .register('only screen and (min-width: 600px)', widescreenHandlers, shouldDegrade)
      .listen(200)
  },
}

function isWidescreen() {
  if (!window.getComputedStyle) {
    return false
  }
  const content = window.getComputedStyle(document.body, ':after').getPropertyValue('content')
  return content === '"widescreen"' || content === 'widescreen' || content === "'widescreen'"
}
